import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import UpcomingEvents from "../components/upcomingevents"
import { Link } from "gatsby";

import { FiArrowRight } from "react-icons/fi";

const Speellijst = () => (
  <Layout>
    <SEO title="Speellijst" />
   
    <HeaderImage />

    <div className="lg:px-4 mt-16 container">
      <div className="lg:flex lg:-mx-4 justify-between">
        <div className="w-full lg:w-1/3 lg:pr-4 max-w-xl -mt-12">
          <div className="lg:sticky lg:top-0 pt-12">
            <h2 className="text-sand-400 text-4xl lg:text-5xl leading-none uppercase mb-2 font-semibold">Speellijst</h2> 
            <p className="text-sand-100 text-justify mb-8 max-w-xl">
            Kom binnenkort eens een kijkje bij ons nemen! In deze speellijst vind je wanneer we weer samenkomen. 
            </p>     

            <div className="flex flex-col leading-tight my-4 text-left">
              <Link to="/media" className="mb-2 underline">Eerder beeldmateriaal  bekijken.. <FiArrowRight className="inline-block" /></Link>
              <Link to="/contact" className="underline">Contact opnemen om een evenement met ons te organiseren.. <FiArrowRight className="inline-block" /></Link>
            </div>
          </div>
        </div>

        <div className="lg:flex-1 max-w-xl mt-12 lg:mt-16">
          <h3 className="text-sand-600 text-xl lg:text-2xl leading-none uppercase mb-6 lg:mb-12 font-semibold">Aankomende evenementen</h3>
          <UpcomingEvents />
        </div>

      </div>
    </div>


  </Layout>
)

const HeaderImage = () => (
  <StaticQuery
    query={graphql`
      query {
        imageHome: file(relativePath: { eq: "media/328318119968768_o.jpg" }) {
          childImageSharp {
            fluid(
              maxWidth: 2000,
              duotone: {
                highlight: "#e7d7b8",
                shadow: "#212126", 
                opacity:30
              }
            ){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.imageHome.childImageSharp.fluid} className="max-h-half-screen"/>}
  />
)

export default Speellijst
