import React from "react"

import { FiArrowRight } from "react-icons/fi";

const UpcomingEvents = () => (
  <div>
    <div className="flex flex-row justify-between leading-none pb-6 mb-6 border-b border-sand-800">
        <div className="text-lg font-semibold w-1/4 underline text-sand-300">
          Zo 31 Mrt.
        </div>

      <a href="http://hetmuzieklokaal.nl/show/classicurls/" target="_blank" rel="noopener noreferrer" className="flex w-3/4 flex-row flex-grow justify-between">
        <div className="">
          <h3 className="text-xl font-bold break-normal text-sand-100">Utrecht Muzieklokaal</h3>
          <p className="mt-1 text-sand-300">31 maart staan we in Het Muzieklokaal in Utrecht, kom gezellig langs. </p>
          <p className="mt-4 lg:mt-6">Meer informatie <FiArrowRight className="inline-block" /></p>
        </div>
        
        <div className="text-right flex-shrink-0 w-1/4 text-sand-100">16.00 uur</div>
      </a>

    </div>

    <div className="flex flex-row justify-between leading-none pb-6 mb-6 border-b border-sand-800">
      <div className="text-lg font-semibold w-1/4 underline text-sand-300">
        Zo 14 Apr.
      </div>

      <a href="https://saitoti.nl/kaartverkoop" target="_blank" rel="noopener noreferrer" className="flex w-3/4 flex-row flex-grow justify-between">
        <div className="">
          <h3 className="text-xl font-bold break-normal text-sand-100">Benefietconcert Domani Venlo</h3>
          <p className="mt-1 text-sand-300">14 april spelen we tijdens het beniefietconcert van de Stichting Saitoti, voor de kinderen van de 'Masai' in Tanzania.</p>
          <p className="mt-4 lg:mt-6">Meer informatie <FiArrowRight className="inline-block" /></p>
        </div>

        <div className="text-right flex-shrink-0 w-1/4 text-sand-100">19.00 uur</div>
      </a>

    </div>

    <div className="flex flex-row justify-between leading-none">
      <div className="text-lg font-semibold w-1/4 underline text-sand-300">
        Okt. <span className="text-xs">(t.b.a.)</span>
      </div>

      <div className="flex w-3/4 flex-row flex-grow justify-between">
        <div className="">
          <h3 className="text-xl font-bold break-normal text-sand-100">Huiskamerconcert</h3>
          <p className="mt-1 text-sand-300">Meer informatie volgt binnenkort!</p>
        </div>

        <div className="text-right flex-shrink-0 w-1/4 text-sand-100">t.b.a.</div>
      </div>

    </div>
  </div>
)

export default UpcomingEvents